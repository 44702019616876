import { useRouteError, isRouteErrorResponse } from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';

import { MuiDocument } from '~/zidmui/libraries/mui';

//
//

// https://remix.run/docs/en/main/route/error-boundary
export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    let message;

    switch (error.status) {
      case 401:
        message = <p>Oops! Looks like you tried to visit a page that you do not have access to.</p>;
        break;
      case 404:
        message = <p>Oops! Looks like you tried to visit a page that does not exist.</p>;
        break;

      default:
        throw new Error(error.data || error.statusText);
    }

    return (
      <MuiDocument title={`${error.status} ${error.statusText}`}>
        <h1>
          {error.status}: {error.statusText}
        </h1>
        {message}
      </MuiDocument>
    );
  }
};
