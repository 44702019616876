import { Outlet, ClientLoaderFunctionArgs, redirect, MetaFunction } from '@remix-run/react';
import { HydrationBoundary } from '@tanstack/react-query';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ar';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import i18next from 'i18next';
import { useDehydratedState } from 'use-dehydrated-state';

export { ErrorBoundary } from '~/libraries/remix/error-boundary';
export { HydrateFallback } from '~/libraries/remix/hydrate-fallback';
import { MuiDocument, Layout, MuiLinks } from '~/zidmui/libraries/mui';

import { fetchAndActivateOnce } from '~/libraries/firebase';
import {
  getLangDirection,
  getSupportedLanguage,
  Language,
  languagesDefault,
  replaceUnknownLanguageFromRequest,
} from '~/libraries/i18next/resource';
import { ZidderClient, ZidderHeaders } from '~/zidderjs';

import { queryClient, useQueryProfile } from './services';
import { ManagerProfileGetResponse } from './zidderjs/resources/manager/account/profile.types';

//
//

dayjs.extend(localeData);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export const meta: MetaFunction = () => [{ title: 'Zid - Hermes' }];

export const links = () => MuiLinks();

export const clientLoader = async ({ params, request }: ClientLoaderFunctionArgs) => {
  const lang = getSupportedLanguage(params?.lang);

  await validateLang(request, lang);
  await validateStoreId(lang!, params?.storeId);

  const [profile] = await Promise.all([
    window.localStorage.getItem('_t')
      ? queryClient.ensureQueryData(useQueryProfile.getFetchOptions({}))
      : undefined,
    fetchAndActivateOnce(),
    applyDayjsLocale(lang),
  ]);

  await validateUserPath(request, lang as string, Number(params?.storeId), profile);

  return { lang, dir: getLangDirection(lang as Language) };
};

export default function App() {
  const dehydratedState = useDehydratedState();

  return (
    <MuiDocument>
      <HydrationBoundary state={dehydratedState}>
        <Layout>
          <Outlet />
        </Layout>
      </HydrationBoundary>
    </MuiDocument>
  );
}

//
//

const validateLang = async (req: Request, lang?: string) => {
  const current =
    getSupportedLanguage(window?.localStorage?.getItem?.('_lang') || undefined) || languagesDefault;

  i18next.changeLanguage(lang || current);

  if (current !== lang) {
    window?.localStorage?.setItem('_lang', lang || current);
  }

  if (!lang) {
    throw redirect(replaceUnknownLanguageFromRequest(req, current));
  }
};

const applyDayjsLocale = async (lang?: string) => {
  dayjs.locale(lang);
};

const validateStoreId = async (lang: string, storeId?: string) => {
  if (storeId && !(Number(storeId) > 0)) {
    throw redirect('/' + lang + '/login?storeId=' + storeId);
  }

  const headers: ZidderHeaders = {
    acceptLanguage: lang,
  };

  if (storeId) {
    headers.storeId = storeId;
    headers.role = 'manager';
  }

  ZidderClient.setHeadersGolbally(headers);
};

const validateUserPath = async (
  req: Request, // to be used later
  lang: string,
  storeId?: number,
  user?: false | ManagerProfileGetResponse,
) => {
  if (req?.url?.includes('/mui-stuff')) return; // TODO: remove this before going live
  if (storeId && !user) throw redirect('/' + lang + '/login?storeId=' + storeId);
  if (user && storeId !== user?.user?.store?.id)
    throw redirect('/' + lang + '/stores/' + user?.user?.store?.id);
};
