import Analytics from 'analytics';

import { plugins } from './plugins.client';

//
//

export const analytics = Analytics({
  app: 'hermes',
  debug: false,
  plugins,
});
