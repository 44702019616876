import { SnackbarContent, CustomContentProps, closeSnackbar } from 'notistack';
import React from 'react';

import { AlertProps } from '@mui/material';

import { Notification, NotificationProps } from '~/zidmui/components/notification';

//
//

declare module 'notistack' {
  interface EnqueueSnackbar {
    <V extends VariantType>(
      options: OptionsWithExtraProps<V> & { message?: SnackbarMessage } & NotificationProps,
    ): SnackbarKey;
    <V extends VariantType>(
      message: SnackbarMessage,
      options?: OptionsWithExtraProps<V> & NotificationProps,
    ): SnackbarKey;
  }
}

interface SnackNotificationProps extends NotificationProps {
  noClose?: boolean;
  alertProps?: Omit<AlertProps, 'variant'>;
}

//

export const SnackNotification = React.forwardRef<
  HTMLDivElement,
  SnackNotificationProps & CustomContentProps
>(
  (
    {
      heading,
      headingProps = { variant: 'subtitle2' },
      message,
      messages,
      noClose,
      alertProps,
      ...props
    },
    ref,
  ) => {
    let severity: AlertProps['severity'];

    switch (props.variant) {
      case 'success':
        severity = 'success';
        break;
      case 'error':
        severity = 'error';
        break;
      case 'warning':
        severity = 'warning';
        break;
      default:
        severity = 'info';
    }

    return (
      <SnackbarContent ref={ref} role="alert">
        <Notification
          heading={heading}
          headingProps={headingProps}
          messages={messages || message}
          severity={severity}
          onClose={noClose ? undefined : () => closeSnackbar(props.id)}
          {...alertProps}
        />
      </SnackbarContent>
    );
  },
);

SnackNotification.displayName = 'SnackNotification';
