import { useEffect } from 'react';

import { ZidLogo } from '~/zidmui/components/zid-logo';

//
//

declare global {
  interface Window {
    _initialSplash?: boolean;
  }
}

export const AppHydrateFallback = () => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      window._initialSplash = true;
    }
  }, []);

  return typeof document !== 'undefined' && window?._initialSplash ? (
    <SplashScreen message="Loading ... جاري التحميل" />
  ) : (
    <SplashScreen initialRender message="Preparing ... جاري التجهيز" />
  );
};

//
//

type SplashScreenProps = {
  message: React.ReactNode;
  initialRender?: boolean;
};

export const SplashScreen = ({ message, initialRender }: SplashScreenProps) => {
  return (
    <div
      id="splash"
      style={{
        background: initialRender ? '#AE72FF' : '#E8E4EB',
        // position: 'fixed',
        // top: '0',
        // left: '0',
        // bottom: '0',
        // right: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '14vh',
      }}
    >
      <ZidLogo
        id="splash-icon"
        color={initialRender ? '#3C1C54' : '#c4baca'}
        style={{
          width: '210px',
          height: '58px',
          // animation: 'rotate 3s ease-in-out infinite',
          // WebkitAnimation: 'rotate 3s ease-in-out infinite',
        }}
      />
      <p
        id="splash-message"
        style={{
          marginTop: '3rem',
          color: '#1F0433',
          fontSize: '18px',
        }}
      >
        {message}
      </p>
    </div>
  );
};
