import googleAnalytics from '@analytics/google-analytics';
import { AnalyticsPlugin } from 'analytics';

//
//

export const plugins: AnalyticsPlugin[] = [];

if (import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID)
  plugins.push(
    googleAnalytics({
      measurementIds: [import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID],
    }),
  );
