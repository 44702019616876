import { withEmotionCache } from '@emotion/react';
import { Links, Meta, Scripts, ScrollRestoration, useRouteLoaderData } from '@remix-run/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import * as React from 'react';

import {
  Button,
  GlobalStyles,
  unstable_useEnhancedEffect as useEnhancedEffect,
} from '@mui/material';

import { SnackNotification } from '~/zidmui/components/snack-notification';
import { EmotionStyleContext } from '~/zidmui/libraries/emotion/style-context';
import { themeParcel } from '~/zidmui/libraries/mui';

import { clientLoader as RootClientLoader } from '~/root';
import { queryClient } from '~/services/client';

import { MuiMeta } from './meta';

//
//

interface DocumentProps {
  children: React.ReactNode;
  title?: string;
}

export const MuiDocument = withEmotionCache(({ children, title }: DocumentProps, emotionCache) => {
  const clientStyleData = React.useContext(EmotionStyleContext);
  const locale = useRouteLoaderData<typeof RootClientLoader>('root');

  // Only executed on client
  useEnhancedEffect(() => {
    // re-link sheet container
    emotionCache.sheet.container = document.head;
    // re-inject tags
    const tags = emotionCache.sheet.tags;
    emotionCache.sheet.flush();
    tags.forEach(tag => {
      // @ts-expect-error accessing private property workaround
      emotionCache.sheet._insertTag(tag);
    });
    // reset cache to reapply global styles
    clientStyleData.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <html lang={locale?.lang} dir={locale?.dir}>
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="theme-color" content={themeParcel.palette.primary.main} />
        {title ? <title>{title}</title> : null}
        <MuiMeta />
        <Meta />
        <Links />
        <meta name="emotion-insertion-point" content="emotion-insertion-point" />
      </head>
      <body>
        <GlobalStyles
          styles={{
            '.notistack-SnackbarContainer': {
              position: 'fixed',
              zIndex: 900,
              top: '5rem',
              right: '1rem',
            },
            '.notistack-CollapseWrapper': {
              justifyContent: 'flex-end',
            },
          }}
        />

        <SnackbarProvider
          maxSnack={4}
          Components={{
            default: SnackNotification,
            success: SnackNotification,
            warning: SnackNotification,
            info: SnackNotification,
            error: SnackNotification,
          }}
          anchorOrigin={{ vertical: 'top', horizontal: locale?.dir === 'rtl' ? 'left' : 'right' }}
          action={key => <Button onClick={() => closeSnackbar(key)}>Dismiss</Button>}
        >
          <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </SnackbarProvider>

        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
});
