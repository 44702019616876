import { MuiDocument } from '~/zidmui/libraries/mui';

import { AppHydrateFallback } from '~/global/components/app-hydrate-fallback';

//
//

export const HydrateFallback = () => {
  return (
    <MuiDocument>
      <AppHydrateFallback />
    </MuiDocument>
  );
};
