import { useLocation } from '@remix-run/react';
import React, { useEffect } from 'react';

import { analytics } from './index.client';

//
//

export const AnalyticsPageViewProvider: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    analytics.page({
      url: location.pathname,
      path: location.pathname,
      search: location.search,
      title: document.title,
    });
  }, [location]);

  return null;
};
